<template>
  <div class="">
    <b-container fluid="xl">
      <div class="px-xl-5 px-md-2 mx-lg-2">
        <b-row cols-md="2" cols="1">
          <b-col class="text-start pe-lg-5 pe-1"
            ><div>
              <img
                src="../assets/catalogue.png"
                class="mt-md-n5 book-img mx-auto text-center d-flex"
              />
              <h1 class="display-4 fw-stronger">
                Une vie plus sûre dans votre propre maison
              </h1>
              <h5 class="l-height">
                Si vous avez plus de difficulté à monter les escaliers, un
                monte-escalier peut être une solution. Vous serez monté et
                descendu en toute sécurité et confortablement. Dans notre
                brochure gratuite sur les monte-escaliers, vous pouvez lire tout
                ce qui concerne les monte-escaliers à votre guise. Cette
                brochure vous donne une idée des possibilités et des modèles,
                mais aussi de l'achat et du financement.
              </h5>
              <h5 class="l-height fw-stronger">
                Demandez notre brochure et découvrez:
              </h5>
              <ol>
                <li class="h5 l-height mb-0">Astuces et conseils</li>
                <li class="h5 l-height mb-0">
                  La plus grande gamme de marques et de modèles
                </li>
                <li class="h5 l-height mb-0">
                  Des monte-escaliers dans toutes les gammes de prix
                </li>
                <li class="h5 l-height mb-0">
                  Entrez vos coordonnées à droite et recevez la brochure
                  gratuite du monte-escalier
                </li>
              </ol>
            </div></b-col
          >
          <b-col
            ><h1 class="fw-bold mt-5">
              Comparez 17 monte-escaliers de 6 fabricants
            </h1>
            <b-button class="s-btn btn-md px-md-5 px-3 fw-bold">
              Demandez la brochure gratuite et sans engagement </b-button
            ><b-card
              ><div>
                <input
                  type="checkbox"
                  class="hidden-checkbox"
                  v-model="bootCheck"
                />
                <h3>
                  <span class="fw-bold">OUI,</span>envoyez-moi la brochure
                  gratuite du monte-escalier
                </h3>
                <b-form
                  @submit.prevent="onSubmit"
                  v-if="show"
                  class="text-start"
                >
                  <b-form-group
                    id="input-group-1"
                    label="Salutation:"
                    label-for="input-1"
                  >
                    <b-form-select
                      id="input-1"
                      v-model="form.gender"
                      :options="gender"
                      required
                      class="form-control"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    id="input-group-2"
                    label="Prénom:"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      :state="fNameValidation"
                      @input="handleFieldInput('firstname')"
                      v-model="form.firstname"
                      placeholder="Par exemple Lore"
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback :state="fNameValidation">
                      <!-- Seules les lettres sont autorisées. -->
                      voornaam niet correct
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    id="input-group-3"
                    label="nom de famille:"
                    label-for="input-3"
                  >
                    <b-form-input
                      id="input-3"
                      :state="lNameValidation"
                      @input="handleFieldInput('lastname')"
                      v-model="form.lastname"
                      placeholder="Par exemple Rosier"
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback :state="lNameValidation">
                      <!-- Seules les lettres sont autorisées. -->
                      achternaam niet correct
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    id="input-group-4"
                    label="numéro de téléphone:"
                    label-for="input-4"
                  >
                    <b-form-input
                      id="input-4"
                      :state="phoneValidation"
                      v-model="form.phone_number"
                      @input="handleFieldInput('phone_number')"
                      type="tel"
                      placeholder="Par exemple 0198765432"
                      maxlength="10"
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback :state="phoneValidation">
                      <!-- Le numéro de téléphone doit contenir 10 chiffres. -->
                      telefoonnummer niet correct
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    id="input-group-5"
                    label="E-mail:"
                    label-for="input-5"
                  >
                    <b-form-input
                      :state="emailValidation"
                      id="input-5"
                      v-model="form.email"
                      @input="handleFieldInput('email')"
                      type="email"
                      placeholder="Par exemple lorerosier@gmail.com"
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback :state="emailValidation">
                      E-mailadres niet correct
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    id="input-group-6"
                    v-slot="{ ariaDescribedby }"
                    class="py-3"
                  >
                    <b-form-checkbox-group
                      v-model="form.answers"
                      id="checkboxes-6"
                      :aria-describedby="ariaDescribedby"
                      required
                    >
                      <b-form-checkbox
                        value="4462"
                        required
                        class="d-flex align-items-baseline"
                        ><h6 class="ms-3">
                          Oui, je souhaite recevoir ces informations et Mobilae
                          peut me contacter par e-mail et/ou par téléphone.
                          J'accepte les termes et conditions. *
                        </h6></b-form-checkbox
                      >
                    </b-form-checkbox-group>
                  </b-form-group>

                  <b-button
                    type="submit"
                    variant="primary"
                    class="s-btn btn-lg fw-bold"
                    :disabled="getLoader"
                    ><b-spinner v-if="getLoader" class="text-center" /><span
                      v-else
                      >Demander une brochure >></span
                    >
                  </b-button>
                </b-form>
              </div>
            </b-card></b-col
          >
        </b-row>
      </div>
    </b-container>
    <div class="hero bg-color py-4 py-md-5 mt-4">
      <b-container fluid="xl">
        <div class="px-xl-5 px-md-2 mx-lg-2">
          <b-row cols-md="2" cols="1" class="align-items-center">
            <b-col class="text-start"
              ><div>
                <h1 class="fw-stronger">
                  Trouvez le monte-escalier adapté à votre situation
                </h1>
                <h5 class="l-height">
                  Vous souhaitez remonter ou redescendre facilement ? Avec un
                  monte-escalier Mobilae, vous restez mobile, sûr et indépendant
                  à la maison. En tant que conseiller indépendant à vos côtés,
                  nous vous aidons à choisir le bon monte-escalier. Notre vaste
                  gamme ne contient que des modèles de haute qualité des
                  meilleurs fabricants. Avec vous, nous passons en revue les
                  différentes options. Nous examinons quel modèle de quel
                  fabricant convient le mieux à votre situation de vie
                  personnelle, à vos souhaits et à vos besoins. Avec 30 ans
                  d'expérience à travers l'Europe, nous veillons à ce que chaque
                  étape vers votre nouveau monte-escalier soit simple et bien
                  pensée. Du conseil à l'installation et au service client. Nos
                  garanties uniques et notre entretien annuel garantissent votre
                  satisfaction pour les années à venir.
                </h5>
              </div>
            </b-col>
            <b-col>
              <div>
                <img src="../assets/afspraak.png" class="w-100 rounded" />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FormSection",

  data() {
    return {
      bootCheck: false,
      validationCheck: false,
      emailErr: true,
      temp_answers: {
        answers: [],
      },
      form: {
        language: "fr_FR",
        publisher_id: "",
        site_subid: "",
        site_custom_url:
          "http://mobilae.renovationdomiciliaire.fr/monte-escaliers",
        site_custom_name: "Mobilae_Trapliften_FR",
        ip: "91.180.156.50",
        optin_timestamp: "2022-05-12 14:28:25",
        date_of_birth: "1975-04-08",
        answers: [],
        gender: "null",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
      },
      gender: [
        { text: "Salutation", value: null, disabled: true },
        { text: "M.", value: "male", disabled: false },
        { text: "Mme.", value: "female", disabled: false },
      ],
      show: true,
    };
  },

  methods: {
    ...mapActions(["postLead"]),
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    resetForm() {
      this.form = {
        language: "fr_FR",
        publisher_id: "1",
        site_custom_url:
          "http://mobilae.renovationdomiciliaire.fr/monte-escaliers",
        site_custom_name: "Mobilae_Trapliften_FR",
        ip: "91.180.156.50",
        optin_timestamp: "2022-05-12 14:28:25",
        date_of_birth: "1975-04-08",
        answers: [4462],
        gender: "null",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
      };
    },
    async onSubmit() {
      if (this.validationCheck) {
        // console.log({
        //   ...this.form,
        //   site_subid: this.$route.query.clickid || "",
        //   publisher_id: this.$route.query.site || "",
        // });

        let resp = await this.postLead({
          ...this.form,
          bootCheck: this.bootCheck,
          site_subid: this.$route.query.clickid || "",
          publisher_id: this.$route.query.site || "",
        });
        if (resp.success == true) {
          this.resetForm();
          this.$router.push("bedankt");
        }
        console.log(resp);
      }
    },
  },
  computed: {
    ...mapGetters(["getLoader", "getErrorResponses"]),
    fNameValidation() {
      if (this.form.firstname == "" && !this.getErrorResponses.firstname) {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.getErrorResponses.firstname == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.firstname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    lNameValidation() {
      if (this.form.lastname == "" && !this.getErrorResponses.lastname) {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;

      if (this.getErrorResponses.lastname == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.lastname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidation() {
      if (this.form.email == "" && !this.getErrorResponses.email) {
        this.validationCheck = false;
        return null;
      }
      if (this.getErrorResponses.email == false) {
        this.validationCheck = false;
        return false;
      } else if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      ) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    phoneValidation() {
      if (
        this.form.phone_number == "" &&
        !this.getErrorResponses.phone_number
      ) {
        this.validationCheck = false;
        return null;
      }

      let phoneRegex = /^0\d{9}$/;
      if (this.getErrorResponses.phone_number == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.phone_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
$enable-negative-margins: true;
.hidden-checkbox {
  opacity: 0;
}
.custom-select {
  appearance: revert !important;
}
.form-control {
  padding: 0.75rem 0.75rem !important;
}
.s-btn {
  padding: 1rem !important;
  width: 100%;
  background-color: #ff5a00 !important;
  border: 0 !important;
  border-radius: 15px !important;
  @media (min-width: 767px) and (max-width: 990px) {
    padding: 1rem !important;
  }
}
.book-img {
  width: 100%;
  @media (max-width: 767.9px) {
    width: 50% !important;
  }
}
.card {
  margin: 50px 0;
  border-radius: 10px !important;
}
.card-body {
  background-color: #50a89e !important;
  color: white;
  padding: 50px !important;
  border-radius: 7px;
  @media (max-width: 990px) {
    padding: 30px 15px !important;
  }
}
.opacity {
  opacity: 0.6;
}
label {
  padding: 7px 0;
}
@media (min-width: 768px) {
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
}
.bg-color {
  background-color: #b9e6ff !important;
}
.l-height {
  line-height: 1.9 !important;
}
</style>
