<template>
  <div>
    <b-container fluid="xl"> <Navbar /></b-container>
    <div class="hero"><img src="../assets/traplift-header-desktop.jpg" /></div>
    <div class="center p-1">
      <div class="textAlign">
        <p class="d-flex flex-column align-items-center m-0">
          <b>gratuite<br /><b>sur les monte-escaliers</b><br />brochure</b>
          <img class="centerArrow" src="../assets/circle.png" width="75px" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "HeaderH",
  components: {
    Navbar,
  },
};
</script>

<style scoped>
.center {
  /* height: 200px;
  width: 200px; */
  height: 260px;
  width: 260px;
  background-color: #ff4800;
  border-radius: 50%;
  position: absolute;
  top: 32%;
  left: 26%;
  color: white;
  text-align: center;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.centerArrow {
  /* margin-left: 63px; */
  width: 30%;
  user-select: none;
}
@media (max-width: 1280px) {
  .center {
    /* height: 200px;
    width: 200px; */
    height: 240px;
    width: 240px;
    background-color: #ff4800;
    border-radius: 50%;
    position: absolute;
    /* top: 34%; */
    top: 26%;
    left: 26%;
    color: white;
    text-align: center;
    font-size: 25px;
  }
  /* .centerArrow {
    margin-left: 63px;
    user-select: none;
    pointer-events: none;
  } */
}

@media (max-width: 1199.99px) {
  .center {
    top: 24%;
  }
}

@media (max-width: 1024.99px) {
  .center {
    top: 15%;
  }
}

@media (max-width: 915px) {
  .center {
    /* height: 200px;
    width: 200px; */
    width: 210px;
    height: 210px;
    background-color: #ff4800;
    border-radius: 50%;
    position: absolute;
    /* top: 10%; */
    top: 15%;
    left: 20%;
    color: white;
    text-align: center;
    font-size: 25px;
  }
}

@media (max-width: 820px) {
  .center {
    /* height: 160px;
    width: 160px; */
    height: 210px;
    width: 210px;

    background-color: #ff4800;
    border-radius: 50%;
    position: absolute;
    top: 19%;

    left: 26%;
    color: white;
    text-align: center;
    font-size: 21px;
  }
  /* .centerArrow {
    width: 57px;
    margin-left: 53px;
    user-select: none;
  } */
}
@media (max-width: 767.99px) {
  .center {
    height: 135px;
    width: 135px;
    background-color: #ff4800;
    border-radius: 50%;
    position: absolute;
    top: 20%;
    left: 23%;
    text-align: center;
    font-size: small;
  }
}

@media (max-width: 575.99px) {
  .center {
    /* height: 80px;
    width: 80px; */
    height: 100px;
    width: 100px;
    background-color: #ff4800;
    border-radius: 50%;
    position: absolute;
    top: 20%;
    left: 23%;
    color: white;
    text-align: center;
    font-size: x-small;
  }
  /* .centerArrow {
    width: 35px;
    margin-left: 25px;
    user-select: none;
  } */
}
@media (max-width: 473.99px) {
  .center {
    font-size: 8px !important;
    width: 80px !important;
    height: 80px !important;
    top: 20%;
  }
}
@media (max-width: 420.99px) {
  .center {
    top: 14%;
  }
}

.textAlign {
  color: white;
  /* margin-top: 14%; */
  user-select: none;
}
</style>
