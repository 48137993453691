<template>
  <div class="bg-secondary px-xl-5 px-md-2 py-4">
    <b-container fluid="xl" class="px-sm-auto px-0">
      <div class="d-flex justify-content-center">
        <b-link v-b-modal.modal-lg class="px-md-3 px-2 footer_links"
          >Politique de confidentialité</b-link
        >
        <b-link v-b-modal.modal-lg1 class="px-md-3 px-2 footer_links"
          >Mentions Légales</b-link
        >
        <b-link v-b-modal.modal-lg2 class="px-md-3 px-2 footer_links"
          >Déclaration en matière de cookies</b-link
        >
        <b-link
          class="px-md-3 px-2 footer_links"
          href="https://s3-eu-west-1.amazonaws.com/cdn.mobilae.fr/pdf/general-conditions-fr.pdf"
          target="_blank"
          >Nos CGV</b-link
        >
        <!-- <b-link v-b-modal.modal-lg3 class="px-md-3 px-2">Nos CGV</b-link> -->
      </div>
      <b-modal
        id="modal-lg"
        size="lg"
        title="La déclaration de confidentialité"
        ok-only
      >
        <!-- <iframe
          sandbox="allow-scripts"
          src="https://www.mobilae.be/privacy-policy"
          width="100%"
          height="400px"
        >
      </iframe> -->
        <div>
          <p>
            Nous traitons les données personnelles de clients (potentiels) et
            pouvons, dans ce cadre, être considérés comme responsable du
            traitement au sens du Règlement Général sur la Protection des
            Données. Nous considérons que le traitement approprié des données
            personnelles est d'une grande importance et nous avons conscience de
            la législation applicable sur la protection de la vie privée. Cette
            déclaration de confidentialité explique comment vos données
            personnelles sont traitées.<br />
          </p>
          <p>
            <strong>Nos coordonnées sont:</strong><br />Mobilae<br />89 Impasse
            Louis Joseph Vicat<br />83600 FREJUS<br />France<br />0 800 500
            201<br /><a href="mailto:contact@mobilae.fr" target="_blank"
              >contact@mobilae.fr</a
            >
          </p>
          <p>
            <strong>Coordonnées du délégué à la protection des données</strong
            ><br />Jan van Duijn&nbsp; &nbsp; &nbsp;
            &nbsp;privacy@mobilae.com<br /><br />
          </p>
          <p>
            <strong>Quelles sont les données traitées&nbsp;?</strong><br />
            Nous traitons, entre autres, les catégories suivantes de données
            personnelles vous concernant&nbsp;: vos nom et prénom, adresse et
            autres coordonnées, date de naissance, sexe et autres données
            personnelles que vous fournissez activement, par exemple en créant
            un profil sur notre site Internet. Nous traitons également votre
            adresse IP, vos données de localisation, vos données relatives à vos
            activités sur notre site Internet et votre comportement de
            navigation sur différents sites Internet (par exemple, parce que
            cette société fait partie d'un réseau publicitaire).
          </p>
          <p>
            <strong
              >À quelles fins et sur quelle base vos données sont-elles
              traitées&nbsp;? </strong
            ><br />Nous traitons les données personnelles vous concernant&nbsp;:
          </p>
          <ul>
            <li>
              <p>
                aux fins des accords que vous concluez, concluez ou avez conclus
                avec nous afin de pouvoir vous fournir nos services et produits
                et/ou dans le but de donner des conseils sur nos services et
                produits&nbsp;;
              </p>
            </li>
            <li>
              <p>
                pour être en mesure de mieux répondre à votre situation
                personnelle lors de la prestation de nos services&nbsp;;
              </p>
            </li>
            <li>
              <p>
                pour nos activités de marketing, y compris le développement de
                la relation client&nbsp;;
              </p>
            </li>
            <li>
              <p>
                pour les analyses des données personnelles afin d'améliorer la
                gamme de produits et de services et de mieux les adapter aux
                souhaits de nos clients (potentiels)&nbsp;;
              </p>
            </li>
            <li>
              <p>
                pour analyser votre comportement sur notre site Internet afin
                d'améliorer notre site Internet et d'adapter notre gamme de
                produits et services à vos préférences.
              </p>
            </li>
          </ul>
          <p>
            Nous traitons vos données personnelles si nous sommes légalement
            obligés de le faire, lorsque le traitement est nécessaire à
            l'exécution du contrat conclu avec vous pour la livraison de nos
            produits et/ou services. Il est également possible que nous
            traitions vos données personnelles afin de promouvoir un intérêt
            légitime. Un intérêt légitime est généralement de nature juridique
            (p. ex. pour se défendre contre des actions en justice), financière
            ou commerciale (p. ex. à des fins administratives). Par exemple,
            nous avons un intérêt légitime à vous envoyer, en tant que client,
            de la publicité pour nos autres services et produits. Enfin, nous
            traitons certaines données personnelles parce que vous nous en avez
            donné l'autorisation. Vous avez le droit de retirer votre
            consentement à tout moment. Ce retrait ne porte pas atteinte à la
            légitimité du traitement sur la base du consentement préalable au
            retrait.
          </p>
          <p>
            <strong>Partage de données personnelles avec des tiers</strong
            ><br />Nous ne vendons/partageons vos données personnelles à/avec
            des tiers que si cela est nécessaire pour l'exécution du contrat
            conclu avec vous ou si cela est nécessaire pour se conformer à une
            obligation légale. Nous concluons un contrat de traitement avec des
            entreprises qui traitent vos données personnelles en notre nom. De
            cette façon, le même niveau de sécurité et de confidentialité est
            garanti. Nous ne divulguons pas vos données personnelles à un pays
            tiers ou à une organisation internationale.
          </p>
          <p>
            <strong>Sécurité et durée de conservation<br /></strong>Nous prenons
            la protection de vos données au sérieux et prenons les mesures
            appropriées pour prévenir les abus, les pertes, les accès non
            autorisés, les divulgations non désirées et les modifications non
            autorisées. Si vous estimez que vos données ne sont pas sécurisées
            ou qu'il y a des indications d'utilisation abusive, veuillez
            contacter notre service clientèle ou nous envoyer un e-mail à
            l’adresse privacy@mobilae.com.
          </p>
          <p>
            Vos données personnelles ne seront pas conservées plus longtemps que
            nécessaire pour atteindre les objectifs pour lesquels vos données
            sont traitées. Sont exclues les données que nous devons conserver
            plus longtemps parce que la législation ou la réglementation nous y
            oblige. Notre durée de conservation dépend des finalités liées au
            traitement.
          </p>
          <p>
            Dans le cadre des contrats que vous avez conclus pour nous permettre
            de vous fournir nos services et produits et/ou de vous conseiller
            sur nos services et produits, nous sommes tenus de conserver vos
            données pendant au moins sept ans. Vos données seront effacées à
            cette fin après cette période.
          </p>
          <p>
            Si vous vous êtes abonné à la newsletter et que nous vous informons
            de cette façon des dernières nouvelles et de nos offres, nous
            traiterons vos données personnelles à des fins de marketing. Si vous
            avez indiqué que vous ne souhaitez plus recevoir la newsletter, nous
            traiterons vos données à cette fin dans les douze mois suivant la
            réception de votre désinscription.
          </p>
          <p>
            Si vous avez été en contact avec nos conseillers téléphoniques et
            que vous avez indiqué que vous ne souhaitez plus être contacté, cela
            sera implémenté dans notre système. Bien entendu, nous ne vous
            contacterons plus. Vos données ne seront plus traitées à cette fin à
            partir de douze mois après l’implémentation. Sauf indication
            contraire, vous ne serez pas désabonné de la newsletter.<br />
          </p>
          <p>
            Si vous avez eu une consultation à domicile et que vous indiquez que
            vous ne souhaitez plus être contacté, cela sera implémenté dans
            notre système. Bien entendu, nous ne vous contacterons plus. Vos
            données ne seront plus traitées à cette fin à partir de dix-huit
            mois après l’implémentation. Sauf indication contraire, vous ne
            serez pas désabonné de la newsletter.
          </p>
          <p>
            <strong>Accès, rectification et effacement<br /></strong>Vous
            disposez d'un droit d'accès, de rectification ou d’effacement de vos
            données personnelles. En outre, vous avez le droit de limiter le
            traitement de vos données personnelles, le droit de vous opposer au
            traitement de vos données personnelles et le droit à la portabilité
            des données.
          </p>
          <p>
            Vous pouvez envoyer une demande d'accès, de rectification,
            d’effacement, de restriction, de transfert de vos données
            personnelles ou d'opposition au traitement de vos données
            personnelles à l’adresse privacy@mobilae.com. Pour vous assurer que
            vous êtes la personne qui a introduit les demandes ci-dessus,
            veuillez joindre à votre demande une copie de votre carte d'identité
            sur laquelle vous avez noirci votre photo d'identité et votre numéro
            de registre national (NRN). L'objectif étant de protéger votre vie
            privée. Nous répondrons à votre demande dans les plus brefs délais,
            au plus tard dans un délai d’un mois. Veuillez noter que vous avez
            la possibilité de déposer une plainte auprès de l'Autorité de
            protection des données.
          </p>
          <p>
            <strong>Mineur<br /></strong>Nous n'avons pas l'intention de
            collecter des données sur les visiteurs du site Internet âgés de
            moins de 16&nbsp;ans. À moins d’avoir la permission des parents ou
            du tuteur, nous ne pouvons pas vérifier si un visiteur a plus de
            16&nbsp;ans. Nous recommandons donc que les parents soient impliqués
            dans les activités en ligne de leurs enfants afin d'empêcher la
            collecte de données sur les enfants sans le consentement des
            parents. Si vous êtes convaincu que nous avons collecté des données
            personnelles sur un mineur sans ce consentement, veuillez nous
            contacter à l’adresse privacy@mobilae.com et nous supprimerons ces
            informations.
          </p>
          <p>
            <strong>Identification de visite sur le site Internet<br /></strong
            >Nous utilisons
            <a href="https://www.mobilae.fr/cookies">des cookies</a>
            fonctionnels, analytiques et de suivi. Un cookie est un petit
            fichier texte qui est stocké dans le navigateur de votre ordinateur,
            tablette ou smartphone lors de votre première visite sur ce site
            Internet. D'une part, nous utilisons des cookies avec une
            fonctionnalité purement technique. Ceux-ci assurent le bon
            fonctionnement du site Internet, ils servent par exemple à se
            souvenir de vos paramètres préférés et nous aident à faire
            fonctionner le site Internet correctement. Il nous permet également
            d'optimiser notre site Internet. De plus, nous plaçons des cookies
            qui suivent votre comportement de navigation afin de pouvoir vous
            fournir un contenu personnalisé et des publicités. Lors de votre
            première visite, nous vous avons déjà informé de l'existence de ces
            cookies et vous avons demandé l'autorisation de les placer. Vous
            pouvez vous désabonner des cookies en configurant votre navigateur
            Internet de telle sorte qu'il ne stocke plus de cookies. En outre,
            vous pouvez également supprimer toutes les informations précédemment
            stockées par le biais des paramètres de votre navigateur. Des
            cookies sont également placés par des tiers sur ce site Internet. Il
            s'agit par exemple des annonceurs et/ou des sociétés de médias
            sociaux.
          </p>
        </div>
      </b-modal>
      <b-modal
        id="modal-lg1"
        size="lg"
        title="IDENTIFICATION ET PUBLICATION."
        ok-only
      >
        <!-- <iframe
          sandbox="allow-scripts"
          src="https://www.mobilae.be/impressum"
          width="100%"
          height="400px"
        >
        </iframe> -->
        <div>
          <p class="my-2">
            <strong>Politique sur la vie privée</strong><br />Nous traitons les
            données personnelles de clients (potentiels) et pouvons, dans ce
            cadre, être considérés comme responsable du traitement au sens du
            Règlement Général sur la Protection des Données. Nous considérons
            que le traitement approprié des données personnelles est d'une
            grande importance et nous avons conscience de la législation
            applicable sur la protection de la vie privée. Cette déclaration de
            confidentialité explique comment vos données personnelles sont
            traitées.&nbsp;
          </p>
          <p>
            <strong>Le présent site internet est publié par&nbsp;<br /></strong
            >Mobilae<br />Siège social :&nbsp;89 impasse Louis Vicat<br />83600
            FREJUS<br />Téléphone : 04 94 51 74 74&nbsp;<br />Fax : 04 94 51 74
            75<br />Pour contacter Mobilae France par email, à l’adresse
            suivante :
            <a href="mailto:service@mobilae.fr" target="_blank"
              >service@mobilae.fr</a
            >
          </p>
          <p><strong>Forme juridique</strong></p>
          <p>
            Mobilae est le nom commercial de la SARL Centre de Confort et de
            Mobilité.<br />Sarl au Capital de 100 000 €<br />Immatriculée au RCS
            FREJUS 23/03/1999<br />SIREN : 422 294 637<br />TVA intra : FR00 422
            294 637<br />Négoce et prestation de services dans les domaines
            médico-techniques (4774Z)<br /><br /><strong
              >Conception graphique et développement du site:<br /></strong
            >Mobilae<br />Lageweg 53<br />2222 AG Katwijk (Pays Bas)<br />0714097171
          </p>
          <p>
            <strong>Le présent site est hébergé par:</strong><br />Arsys
            Internet, E.U.R.L.<br />102, avenue de la Salanque B.P.40518<br />66005
            Perpignan Cedex - France<br />Téléphone: 0800 940 865<br />Fax 04 68
            34 25 66<br />Le présent site est la propriété de Mobilae France.
          </p>
          <p>
            <strong
              >Coordonnées du délégué à la protection des
              données&nbsp;&nbsp;</strong
            ><br />Jan van Duijn privacy@mobilae.com<br />&nbsp;<br /><strong
              >Quelles sont les données traitées ?</strong
            ><br />Nous traitons, entre autres, les catégories suivantes de
            données personnelles vous concernant : vos nom et prénom, adresse et
            autres coordonnées, date de naissance, sexe et autres données
            personnelles que vous fournissez activement, par exemple en créant
            un profil sur notre site Internet. Nous traitons également votre
            adresse IP, vos données de localisation, vos données relatives à vos
            activités sur notre site Internet et votre comportement de
            navigation sur différents sites Internet (par exemple, parce que
            cette société fait partie d'un réseau publicitaire).
          </p>
          <p>
            <strong
              >À quelles fins et sur quelle base vos données sont-elles traitées
              ?&nbsp;</strong
            ><br />Nous traitons les données personnelles vous concernant :
          </p>
          <ul>
            <li>
              aux fins des accords que vous concluez, conclurez ou avez conclus
              avec nous afin de pouvoir vous fournir nos services et produits
              et/ou dans le but de donner des conseils sur nos services et
              produits ;
            </li>
            <li>
              pour être en mesure de mieux répondre à votre situation
              personnelle lors de la prestation de nos services ;
            </li>
            <li>
              pour nos activités de marketing, y compris le développement de la
              relation client ;
            </li>
            <li>
              pour les analyses des données personnelles afin d'améliorer la
              gamme de produits et de services et de mieux les adapter aux
              souhaits de nos clients (potentiels) ;
            </li>
            <li>
              pour analyser votre comportement sur notre site Internet afin
              d'améliorer notre site Internet et d'adapter notre gamme de
              produits et services à vos préférences.
            </li>
          </ul>
          <p>
            Nous traitons vos données personnelles si nous sommes légalement
            obligés de le faire, lorsque le traitement est nécessaire à
            l'exécution du contrat conclu avec vous pour la livraison de nos
            produits et/ou services. Il est également possible que nous
            traitions vos données personnelles afin de promouvoir un intérêt
            légitime. Un intérêt légitime est généralement de nature juridique
            (p. ex. pour se défendre contre des actions en justice), financière
            ou commerciale (p. ex. à des fins administratives). Par exemple,
            nous avons un intérêt légitime à vous envoyer, en tant que client,
            de la publicité pour nos autres services et produits. Enfin, nous
            traitons certaines données personnelles parce que vous nous en avez
            donné l'autorisation. Vous avez le droit de retirer votre
            consentement à tout moment. Ce retrait ne porte pas atteinte à la
            légitimité du traitement sur la base du consentement préalable au
            retrait.&nbsp;<br />&nbsp;<br /><strong
              >Partage de données personnelles avec des tiers</strong
            ><br />Nous ne vendons/partageons vos données personnelles à/avec
            des tiers que si cela est nécessaire pour l'exécution du contrat
            conclu avec vous ou si cela est nécessaire pour se conformer à une
            obligation légale. Nous concluons un contrat de traitement avec des
            entreprises qui traitent vos données personnelles en notre nom. De
            cette façon, le même niveau de sécurité et de confidentialité est
            garanti. Nous ne divulguons pas vos données personnelles à un pays
            tiers ou à une organisation internationale.&nbsp;
          </p>
          <p>
            <strong>Sécurité et durée de conservation&nbsp;</strong><br />Nous
            prenons la protection de vos données au sérieux et prenons les
            mesures appropriées pour prévenir les abus, les pertes, les accès
            non autorisés, les divulgations non désirées et les modifications
            non autorisées. Si vous estimez que vos données ne sont pas
            sécurisées ou qu'il y a des indications d'utilisation abusive,
            veuillez contacter notre service clientèle ou nous envoyer un e-mail
            à l’adresse privacy@mobilae.com.
          </p>
          <p>
            Vos données personnelles ne seront pas conservées plus longtemps que
            nécessaire pour atteindre les objectifs pour lesquels vos données
            sont traitées. Sont exclues les données que nous devons conserver
            plus longtemps parce que la législation ou la réglementation nous y
            oblige. Notre durée de conservation dépend des finalités liées au
            traitement.<br />&nbsp;<br />Dans le cadre des contrats que vous
            avez conclus pour nous permettre de vous fournir nos services et
            produits et/ou de vous conseiller sur nos services et produits, nous
            sommes tenus de conserver vos données pendant au moins sept ans. Vos
            données seront effacées à cette fin après cette période.&nbsp;<br />&nbsp;<br />Si
            vous vous êtes abonné à la newsletter et que nous vous informons de
            cette façon des dernières nouvelles et de nos offres, nous
            traiterons vos données personnelles à des fins de marketing. Si vous
            avez indiqué que vous ne souhaitez plus recevoir la newsletter, nous
            traiterons vos données à cette fin dans les douze mois suivant la
            réception de votre désinscription.<br />&nbsp;<br />Si vous avez été
            en contact avec nos conseillers téléphoniques et que vous avez
            indiqué que vous ne souhaitez plus être contacté, cela sera
            implémenté dans notre système. Bien entendu, nous ne vous
            contacterons plus. Vos données ne seront plus traitées à cette fin à
            partir de douze mois après l’implémentation. Sauf indication
            contraire, vous ne serez pas désabonné de la newsletter.<br />Si
            vous avez eu une consultation à domicile et que vous indiquez que
            vous ne souhaitez plus être contacté, cela sera implémenté dans
            notre système. Bien entendu, nous ne vous contacterons plus. Vos
            données ne seront plus traitées à cette fin à partir de dix-huit
            mois après l’implémentation. Sauf indication contraire, vous ne
            serez pas désabonné de la newsletter.<br /><br /><strong
              >Accès, rectification et effacement</strong
            ><br />Vous disposez d'un droit d'accès, de rectification ou
            d’effacement de vos données personnelles. En outre, vous avez le
            droit de limiter le traitement de vos données personnelles, le droit
            de vous opposer au traitement de vos données personnelles et le
            droit à la portabilité des données.&nbsp;
          </p>
          <p>
            Vous pouvez envoyer une demande d'accès, de rectification,
            d’effacement, de restriction, de transfert de vos données
            personnelles ou d'opposition au traitement de vos données
            personnelles à l’adresse privacy@mobilae.com. Pour vous assurer que
            vous êtes la personne qui a introduit les demandes ci-dessus,
            veuillez joindre à votre demande une copie de votre carte d'identité
            sur laquelle vous avez noirci votre photo d'identité et votre numéro
            de registre national (NRN). L'objectif étant de protéger votre vie
            privée. Nous répondrons à votre demande dans les plus brefs délais,
            au plus tard dans un délai d’un mois. Veuillez noter que vous avez
            la possibilité de déposer une plainte auprès de l'Autorité de
            protection des données.&nbsp;
          </p>
          <p>
            <strong>Mineur</strong><br />Nous n'avons pas l'intention de
            collecter des données sur les visiteurs du site Internet âgés de
            moins de 16 ans. À moins d’avoir la permission des parents ou du
            tuteur, nous ne pouvons pas vérifier si un visiteur a plus de 16
            ans. Nous recommandons donc que les parents soient impliqués dans
            les activités en ligne de leurs enfants afin d'empêcher la collecte
            de données sur les enfants sans le consentement des parents. Si vous
            êtes convaincu que nous avons collecté des données personnelles sur
            un mineur sans ce consentement, veuillez nous contacter à l’adresse
            privacy@mobilae.com et nous supprimerons ces informations.<br />&nbsp;<br /><strong
              >Identification de visite sur le site Internet</strong
            ><br />Nous utilisons des cookies fonctionnels, analytiques et de
            suivi. Un cookie est un petit fichier texte qui est stocké dans le
            navigateur de votre ordinateur, tablette ou smartphone lors de votre
            première visite sur ce site Internet. D'une part, nous utilisons des
            cookies avec une fonctionnalité purement technique. Ceux-ci assurent
            le bon fonctionnement du site Internet, ils servent par exemple à se
            souvenir de vos paramètres préférés et nous aident à faire
            fonctionner le site Internet correctement. Il nous permet également
            d'optimiser notre site Internet. De plus, nous plaçons des cookies
            qui suivent votre comportement de navigation afin de pouvoir vous
            fournir un contenu personnalisé et des publicités. Lors de votre
            première visite, nous vous avons déjà informé de l'existence de ces
            cookies et vous avons demandé l'autorisation de les placer. Vous
            pouvez vous désabonner des cookies en configurant votre navigateur
            Internet de telle sorte qu'il ne stocke plus de cookies. En outre,
            vous pouvez également supprimer toutes les informations précédemment
            stockées par le biais des paramètres de votre navigateur. Des
            cookies sont également placés par des tiers sur ce site Internet. Il
            s'agit par exemple des annonceurs et/ou des sociétés de médias
            sociaux.
          </p>
        </div>
      </b-modal>
      <b-modal
        id="modal-lg2"
        size="lg"
        title="Déclaration en matière de cookies"
        ok-only
      >
        <!-- <iframe
          sandbox="allow-scripts"
          src="https://www.mobilae.be/cookies"
          width="100%"
          height="400px"
        >
        </iframe> -->
        <p>
          Mobilae utilise des cookies et des techniques similaires. Vous
          trouverez plus d'informations ci-dessous.<br />
        </p>
        <p><strong>Qu'est-ce qu'un cookie ?</strong></p>
        <p>
          Un cookie est un petit fichier texte qui est (temporairement) stocké
          sur le disque dur par votre navigateur et lu sur l'appareil avec
          lequel vous visitez l'un de nos sites Internet. Les informations qui y
          sont stockées peuvent être retournées à nos serveurs lors d'une visite
          ultérieure. En utilisant ces cookies, Mobilae s'assure, entre autres,
          que vous n'avez pas toujours à entrer les mêmes informations lorsque
          vous accédez à nos sites Internet. Ils nous donnent également un
          aperçu de la façon dont vous cliquez sur nos pages. De cette façon,
          nous rendons nos sites Internet plus conviviaux et plus pertinents
          pour vous. L'utilisation de cookies est importante pour le service de
          Mobilae et c'est pourquoi nous souhaitons vous informer au mieux de
          cette utilisation.
        </p>
        <p><strong>Cookies fonctionnels</strong></p>
        <p>
          Les cookies fonctionnels assurent le bon fonctionnement du site
          Internet. Ces cookies stockent les préférences que vous avez
          spécifiées. Par conséquent, le site Internet peut être configuré
          spécifiquement selon vos préférences lorsque vous revenez sur notre
          site Internet. De même, si vous nous avez donné la permission de
          placer des cookies, nous pouvons nous en souvenir au moyen d'un
          cookie. Cela signifie que vous n'avez pas à répéter vos préférences à
          chaque fois, de sorte que vous pouvez profiter d'une utilisation plus
          agréable de notre site Internett.&nbsp;
        </p>
        <p><strong>Cookies publicitaires</strong></p>
        <p>
          Nous donnons à des tiers la possibilité de placer des cookies
          publicitaires via notre site Internet. Cela signifie que, avec votre
          permission, les annonceurs placent des «&nbsp;cookies de suivi&nbsp;»
          sur votre appareil afin d'établir un profil de votre comportement de
          navigation en ligne. Les informations de ces cookies publicitaires
          donnent un aperçu des pages que vous visitez à partir de leur réseau,
          des publicités que vous avez vues, si vous avez cliqué ou non et si
          vous avez également fait une demande par la suite. Ces données sont
          combinées à des informations comparables qu'ils reçoivent lors de
          votre visite sur d'autres sites Internet de leur réseau. Ce profil
          n'est pas lié par Mobilae à vos données à caractère personnel comme
          votre nom, adresse, adresse e-mail et autres informations telles que
          nous les connaissons, mais sert uniquement à identifier vos intérêts
          publicitaires afin que nous puissions vous montrer des annonces
          pertinentes.
        </p>
        <p><strong>Google Analytics</strong></p>
        <p>
          Afin de déterminer quelles parties de notre site Internet vous
          intéressent ou non, nous utilisons des applications analytiques pour
          mesurer en permanence votre comportement sur notre site Internet. Nous
          le faisons notamment en plaçant des cookies. Nous utilisons les
          informations que nous collectons, entre autres, pour des rapports
          contenant des statistiques sur le nombre de visiteurs de notre site
          Internet et la fréquence de consultation des pages. Sur la base de ces
          informations, nous pouvons personnaliser notre site Internet afin
          d'optimiser l'expérience utilisateur pour vous. Il s'agit par exemple
          de l'optimisation de la structure du site Internet, de la navigation
          ou du contenu des pages Internet.
        </p>
        <p>
          Google peut également transmettre des informations analytiques à des
          tiers lorsque la loi l'exige ou lorsque ces tiers traitent ces
          informations pour le compte de Google. Nous n'avons aucune autorité
          sur ce point. Les informations collectées par Google sont rendues
          anonymes dans la mesure du possible. Les informations sont transférées
          et stockées par Twitter, Facebook, Google + et LinkedIn sur des
          serveurs aux États-Unis. LinkedIn, Twitter, Facebook et Google + se
          sont engagés à respecter les principes de la sphère de sécurité et
          sont affiliés au programme «&nbsp;Safe Harbor&nbsp;» du ministère du
          commerce américain. Cela implique un niveau de protection adéquat pour
          le traitement des données personnelles.
        </p>
        <p>
          <strong
            >Aperçu des cookies qui peuvent être utilisés par Mobilae :</strong
          >
        </p>
        <table>
          <thead>
            <tr>
              <th>Partie</th>
              <th>Cookie(s)</th>
              <th>Durée de conservation</th>
              <th>Finalité</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Bing</td>
              <td>MUID</td>
              <td>13 Mois</td>
              <td>Utilisé pour rendre les publicités plus attrayantes.</td>
            </tr>
            <tr>
              <td>Bing</td>
              <td>_uetsid</td>
              <td>1 jour</td>
              <td>Utilisé pour rendre les publicités plus attrayantes.</td>
            </tr>
            <tr>
              <td>Facebook</td>
              <td>fr</td>
              <td>3 Mois</td>
              <td>Utilisé pour rendre les publicités plus attrayantes.</td>
            </tr>
            <tr>
              <td>Doubleclick</td>
              <td>test_cookie, ID</td>
              <td>2 Ans</td>
              <td>Utilisé pour rendre les publicités plus attrayantes.</td>
            </tr>
            <tr>
              <td>NextRoll</td>
              <td>_adroll...</td>
              <td>13 Mois</td>
              <td>Utilisé pour rendre les publicités plus attrayantes.</td>
            </tr>
            <tr>
              <td>Google Adwords</td>
              <td>HSID, APISID,SAPISID, SSID, NID</td>
              <td>1 An</td>
              <td>
                Pour mieux connaître les préférences de nos clients et être en
                mesure de rendre les publicités attrayantes. Sur la base de ces
                informations, nous faisons également affaire avec nos
                partenaires médias en ligne.
              </td>
            </tr>
            <tr>
              <td>Google Analytics</td>
              <td>SID of HSID</td>
              <td>Session</td>
              <td>
                Données utilisateurs protégées contre tout accès non autorisé.
              </td>
            </tr>
            <tr>
              <td>Google Analytics</td>
              <td>_utma, _ga</td>
              <td>2 Ans</td>
              <td>
                Avoir un aperçu du comportement des visiteurs sur le site
                Internet dans le but d'améliorer le site Internet et de mieux
                l'adapter aux besoins de ses visiteurs.
              </td>
            </tr>
            <tr>
              <td>Google Analytics</td>
              <td>_utmb</td>
              <td>Session</td>
              <td>
                Avoir un aperçu du comportement des visiteurs sur le site
                Internet dans le but d'améliorer le site Internet et de mieux
                l'adapter aux besoins de ses visiteurs.
              </td>
            </tr>
            <tr>
              <td>Google Analytics</td>
              <td>_utmc, _utmz</td>
              <td>6 Mois</td>
              <td>
                Avoir un aperçu du comportement des visiteurs sur le site
                Internet dans le but d'améliorer le site Internet et de mieux
                l'adapter aux besoins de ses visiteurs.
              </td>
            </tr>
            <tr>
              <td>Google Analytics</td>
              <td>_gid</td>
              <td>1 Jour</td>
              <td>
                Avoir un aperçu du comportement des visiteurs sur le site
                Internet dans le but d'améliorer le site Internet et de mieux
                l'adapter aux besoins de ses visiteurs.
              </td>
            </tr>
            <tr>
              <td>Google Analytics</td>
              <td>_gat_UA-...</td>
              <td>10 Minutes</td>
              <td>
                Avoir un aperçu du comportement des visiteurs sur le site
                Internet dans le but d'améliorer le site Internet et de mieux
                l'adapter aux besoins de ses visiteurs.
              </td>
            </tr>
            <tr>
              <td>Google TagManager</td>
              <td>gtm_UA-...</td>
              <td>2 Ans</td>
              <td>
                Avoir un aperçu du comportement des visiteurs sur le site
                Internet dans le but d'améliorer le site Internet et de mieux
                l'adapter aux besoins de ses visiteurs.
              </td>
            </tr>
            <tr>
              <td>Visual Website Optimizer</td>
              <td>_vis_opt_exp_..., _vis_opt_s, _vwo_uuid</td>
              <td>100 Jours</td>
              <td>
                Avoir un aperçu du comportement des visiteurs sur le site
                Internet dans le but d'améliorer le site Internet et de mieux
                l'adapter aux besoins de ses visiteurs.
              </td>
            </tr>
            <tr>
              <td>Visual Website Optimizer</td>
              <td>_vis_opt_test_cookie</td>
              <td>Session</td>
              <td>
                Avoir un aperçu du comportement des visiteurs sur le site
                Internet dans le but d'améliorer le site Internet et de mieux
                l'adapter aux besoins de ses visiteurs.
              </td>
            </tr>
            <tr>
              <td>Mobilae</td>
              <td>_otsrc, navigation, cb984530f0, csrc, CraftSessionId</td>
              <td>Unclassified</td>
              <td>
                Collecte des informations de l'utilisateur, comme l'horodatage
                de la première visite et son comportement sur notre site
                Internet. Ces informations sont utilisées pour permettre un
                meilleur fonctionnement du site Internet et nous aider à mieux
                comprendre les préférences de nos clients.<br />
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong><br /></strong>
        </p>
        <p><strong>Désactiver ou supprimer les cookies</strong><br /></p>
        <p>
          Il est possible de désactiver les cookies. Si vous désactivez nos
          cookies, vous devez savoir que nos sites Internet ne fonctionneront
          pas de manière optimale. Si vous ne voulez pas que les sites Internet
          placent des cookies sur votre ordinateur, vous pouvez également
          modifier les paramètres de votre navigateur. La façon de régler ces
          paramètres varie d'un navigateur à l'autre. À cet effet, vous pouvez
          consulter la fonction d'aide de votre navigateur. Découvrez ici
          comment désactiver ou supprimer les cookies dans votre
          navigateur&nbsp;:
          <a
            href="https://support.google.com/chrome/answer/95647?fr=fr&amp;hl=fr"
            target="_blank"
            >Chrome</a
          >,
          <a
            href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies"
            target="_blank"
            >Internet Explorer</a
          >,
          <a
            href="https://support.mozilla.org/fr/kb/effacer-les-cookies-pour-supprimer-les-information"
            target="_blank"
            >Firefox</a
          >,
          <a href="https://support.apple.com/fr-fr/HT201265" target="_blank"
            >Safari</a
          >
          (IOS),
          <a
            href="https://support.apple.com/kb/PH21411?locale=fr_FR"
            target="_blank"
            >Safari (macOS)</a
          >.
        </p>
        <p>
          Certains cookies sont placés par des tiers et vous affichent des
          publicités via notre site Internet, entre autres. Ces cookies peuvent
          être supprimés de manière centralisée via «&nbsp;<a
            href="http://www.youronlinechoices.com/fr/"
            target="_blank"
            >Your Online Choices</a
          >&nbsp;» afin qu'ils ne soient pas placés sur un site Internet tiers.
        </p>
        <p><br /></p>
        <p>
          Cette politique en matière de cookies a été modifiée pour la dernière
          fois le: 16 avril 2021<br />
        </p>
      </b-modal>
      <!-- <b-modal id="modal-lg3" size="lg" title="Algemene Voorwaarden" ok-only>
        <div class="pdfView">
          <vue-pdf-embed :source="source" />
        </div>
      </b-modal> -->
    </b-container>
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
export default {
  name: "Footer",
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      source:
        "https://sponsor-tier-2-server.herokuapp.com/https://firebasestorage.googleapis.com/v0/b/host-only.appspot.com/o/general-conditions-be.pdf?alt=media&token=c2ab290a-07ff-4c04-9f4d-cec318696bb1",
    };
  },
};
</script>

<style lang="scss" scoped>
.bg-secondary {
  background-color: #133c46 !important;
}
.footer_links {
  color: #fff;
  text-decoration: none;
  @media (max-width: 600px) {
    font-size: 0.55rem;
  }
  &:hover {
    text-decoration: none !important;
    color: #fff;
  }
}
.pdfView {
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
